export const rootUri = 'https://tptestapi.village.energy/sat/v1'
export const redirectURI = 'https://devices.village.energy/sn/'
export const login = '/auth/dvijwt'
export const refreshToken = '/auth/refreshdvijwt'
export const createWifiApApi = '/dvi/iot'
export const deviceDetailsApi = '/dvi/veda'
export const updateWifiApi = '/dvi/iot'
export const factoryResetApi = '/dvi/iot'
export const updateVedaSoftware = '/dvi/iot'
export const circuitControlTest = '/dvi/iot'
  
